import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"
import BHBgif from '../img/BHBatST.gif'

export const ChaptersPageTemplate = ({
  fullImage,
  main,
  callout,
  closing
}) => (
  <>
    <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} offset={0} style={{ height: '100%', width: '100%' }}>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          minHeight: '490px',
          height: '60vh',
          backgroundImage: `url(${!!fullImage.childImageSharp ? fullImage.childImageSharp.fluid.src : fullImage
            })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
      </div>
    </ScrollAnimation>
    <section>
      <div className="columns">
        <div className="column is-full-mobile is-half is-flex">
          <div className="section py-6 is-align-self-center" style={{
            maxWidth: '600px',
            margin: '0 0 0 auto'
          }}>
            <h1>{main.heading}</h1>
            <p dangerouslySetInnerHTML={{ __html: main.description }} />

          </div>
        </div>
        <div className="column is-full-mobile is-half">
          <div className="py-6" style={{
            maxWidth: '420px',
            margin: '0 auto'
          }}>
            <img src={BHBgif} alt="Animated Babes Helpin' Babes sticker" />
          </div>
        </div>
      </div>
    </section>
    <section className="has-background-primary">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-11">
            <div className="section has-text-centered">
              {/* <h1>{callout}</h1> */}
              <ScrollAnimation animateIn="animate__flipInX">
                <h1> In order to continuously shift company culture, <mark>chapters are employee-led and supported by BHB through coaching and consulting.</mark></h1>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="columns">
        <div className="column is-full-mobile is-half">
          <div className="section py-6" style={{
            maxWidth: '480px',
            margin: '0 0 0 auto'
          }}>
            <PreviewCompatibleImage imageInfo={closing.image} />
          </div>
        </div>
        <div className="column is-full-mobile is-half">
          <div className="section py-6" style={{
            maxWidth: '555px',
            margin: '0 auto 0 0'
          }}>
            <p>{closing.description}</p>
            <Link className="btn" to='/contact'>MAKE AN INTRODUCTION</Link>
          </div>
        </div>
      </div>
    </section>
  </>
)

ChaptersPageTemplate.propTypes = {
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  callout: PropTypes.string,
  closing: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const ChaptersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ChaptersPageTemplate
        fullImage={frontmatter.fullImage}
        main={frontmatter.main}
        callout={frontmatter.callout}
        closing={frontmatter.closing}
      />
    </Layout>
  )
}

ChaptersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ChaptersPage

export const ChaptersPageQuery = graphql`
  query ChaptersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        fullImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        main {
          heading
          description
        }
        callout
        closing {
          description
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
